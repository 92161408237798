import type { DownloadAnalytics } from '../../AppPage.types';

export const analytics: DownloadAnalytics = {
  android: {
    click: {
      eventName: 'app_androidBtn_click',
      data: {
        position: 2,
      },
    },
  },
  ios: {
    click: {
      eventName: 'app_iosBtn_click',
      data: {
        position: 2,
      },
    },
  },
};
