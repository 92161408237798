import benefit01 from '@public/assets/app/benefit01.webp';
import benefit02 from '@public/assets/app/benefit02.webp';
import benefit03 from '@public/assets/app/benefit03.webp';

import type { Data } from '@/modules/app/AppPage.types';

export const benefits: Array<Data> = [
  {
    id: 1,
    key: 'benefit_1_title',
    default: 'Optimized for your device',
    image: benefit01,
  },
  {
    id: 2,
    key: 'benefit_2_title',
    default: 'Download in 2 clicks',
    image: benefit02,
  },
  {
    id: 3,
    key: 'benefit_3_title',
    default: 'Available without VPN',
    image: benefit03,
  },
];
