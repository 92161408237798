import type { CSSProperties, FC } from 'react';
import { useTranslation } from 'next-i18next';
import BenefitBG from '@public/assets/app/benefit.svg';
import { Image } from '@/components/Image/Image';
import { benefits } from './DownloadBenefits.data';
import styles from './DownloadBenefits.module.scss';
export const DownloadBenefits: FC = () => {
  const {
    t
  } = useTranslation('promo', {
    keyPrefix: 'app'
  });
  return <div className={styles.benefits} style={({
    '--benefits-total': benefits.length
  } as CSSProperties)} data-sentry-component="DownloadBenefits" data-sentry-source-file="DownloadBenefits.tsx">
      {benefits.map(item => <div className={styles.benefit} key={item.id}>
          <BenefitBG className={styles.benefitBg} preserveAspectRatio="xMaxYMid slice" />
          {item?.image && <Image alt={t(item.key, item.default)} src={item.image} width={120} height={120} className={styles.benefitIcon} />}

          {t(item.key, item.default)}
        </div>)}
    </div>;
};