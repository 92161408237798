import { useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { QRCodeSVG } from 'qrcode.react';
import PopupCornerScratch from '@public/assets/app/popupCornerScratch.svg';
import { Button } from '@/components/Button/Button';
import { Popup } from '@/components/Popup/Popup';
import { DownloadButton } from '../DownloadButton/DownloadButton';
import { Instructions } from '../Instructions/Instructions';
import { analytics } from './DownloadSteps.analytics';
import type { DownloadStepsProps } from './DownloadSteps.types';
import styles from './DownloadSteps.module.scss';
export const DownloadSteps = ({
  steps,
  platform,
  qrLink
}: DownloadStepsProps) => {
  const {
    t
  } = useTranslation('promo', {
    keyPrefix: 'app'
  });
  const [instructionsVisible, setInstructionsVisibility] = useState(false);
  const handleCloseInstructions = () => {
    setInstructionsVisibility(false);
  };
  const handleOpenInstructions = () => {
    setInstructionsVisibility(true);
  };
  const stepTransComponents = [<Button key={0} as="button" variant="text" color="white" className={styles.instructions} uppercase={false} onClick={handleOpenInstructions} />];
  return <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={cn(styles.steps, styles.column)}>
            <h3 className={styles.stepsTitle}>{t(steps.title.key, steps.title.default)}</h3>
            {Boolean(steps?.items?.length) && <ol className={styles.stepsList}>
                {steps.items.map(step => <li key={step.id} className={styles.step}>
                    <Trans i18nKey={step.key} t={t} defaults={step.default} components={stepTransComponents} />
                  </li>)}
              </ol>}
          </div>
          <div className={cn(styles.aside, styles.column)}>
            <div className={styles.qrCode}>
              <QRCodeSVG className={styles.qrCodeSvg} value={qrLink} bgColor="transparent" fgColor="#000" level="L" includeMargin={false} data-sentry-element="QRCodeSVG" data-sentry-source-file="DownloadSteps.tsx" />
            </div>
            <div className={styles.qrCodeDescription}>{t('scan_qrcode', 'Scan code with your phone')}</div>
          </div>
        </div>
        <DownloadButton className={styles.downloadButton} platform={platform} analytics={analytics[platform]} data-sentry-element="DownloadButton" data-sentry-source-file="DownloadSteps.tsx" />
      </div>
      <Popup show={instructionsVisible} classes={{
      wrapper: styles.popupWrapper
    }} onClose={handleCloseInstructions} data-sentry-element="Popup" data-sentry-source-file="DownloadSteps.tsx">
        <PopupCornerScratch class={styles.popupCornerScratch} data-sentry-element="PopupCornerScratch" data-sentry-source-file="DownloadSteps.tsx" />
        <Instructions data-sentry-element="Instructions" data-sentry-source-file="DownloadSteps.tsx" />
      </Popup>
    </>;
};