import AndroidIcon from '@public/assets/common/icons/androidWhite.svg';
import AppleIcon from '@public/assets/common/icons/apple.svg';

import type { DataByPlatform } from './DownloadButton.types';

export const dataByPlatform: DataByPlatform = {
  android: {
    key: 'for_android',
    default: 'for Android',
    Icon: AndroidIcon,
  },
  ios: {
    key: 'app_store',
    default: 'App Store',
    Icon: AppleIcon,
  },
};

export const appLinksMap = {
  android: '/api/user/v1/apk/download',
  ios: 'https://apps.apple.com/app/banzai-bet/id6467190917',
};
