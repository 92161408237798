import { type FC } from 'react';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData, handleEventWithAnalytics } from '@mwl/core-lib';
import DownloadButtonBG from '@public/assets/app/downloadButton.svg';
import { appLinksMap, dataByPlatform } from './DownloadButton.data';
import type { DownloadButtonProps } from './DownloadButton.types';
import styles from './DownloadButton.module.scss';
export const DownloadButton: FC<DownloadButtonProps> = ({
  platform,
  className,
  analytics
}) => {
  const {
    t
  } = useTranslation('promo', {
    keyPrefix: 'app'
  });
  const handleDownloadApp = () => {
    const link = document.createElement('a');
    link.href = appLinksMap[platform];
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const buttonClassName = cn(styles.root, className);
  const fullAnalytics = extendAnalyticsData(analytics, {
    cls: buttonClassName,
    href: appLinksMap[platform]
  });
  const {
    Icon,
    key,
    default: defaultTranslate
  } = dataByPlatform[platform];
  return <button onClick={handleEventWithAnalytics(handleDownloadApp, fullAnalytics?.click?.eventName, fullAnalytics?.click?.data)} className={buttonClassName} type="button" data-sentry-component="DownloadButton" data-sentry-source-file="DownloadButton.tsx">
      <div className={styles.label}>
        <span className={styles.title}>{t('download', 'download')}</span>
        <span className={styles.description}>{t(key, defaultTranslate)}</span>
      </div>

      <Icon className={styles.icon} data-sentry-element="Icon" data-sentry-source-file="DownloadButton.tsx" />

      <DownloadButtonBG className={cn(styles.bg, styles[`${platform}Bg`])} data-sentry-element="DownloadButtonBG" data-sentry-source-file="DownloadButton.tsx" />
    </button>;
};