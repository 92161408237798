import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Image } from '@/components/Image/Image';
import { Slider } from '@/components/Slider/Slider';
import { useMediaWidthDown } from '@/hooks';
import { defaultSliderProps, instructions } from './Instructions.data';
import styles from './Instructions.module.scss';
export const Instructions = () => {
  const {
    t
  } = useTranslation('promo', {
    keyPrefix: 'app'
  });
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const isScreenXs = useMediaWidthDown('screenXs');
  return <div className={styles.root} data-sentry-component="Instructions" data-sentry-source-file="Instructions.tsx">
      <div className={styles.title}>{t('instructions_modal_title', 'Instruction')}</div>
      <div className={styles.sliderWrapper}>
        <Slider {...defaultSliderProps} slidesPerView={isScreenXs ? 1.6 : 2} onSlideChange={({
        realIndex
      }) => setActiveIndex(realIndex)} data-sentry-element="Slider" data-sentry-source-file="Instructions.tsx">
          {instructions?.map((item, index) => {
          return <div key={item.key} className={cn(styles.slide, {
            [styles.slideActive]: activeIndex === index,
            [styles.slideLeft]: index < activeIndex,
            [styles.slideRight]: index > activeIndex
          })}>
                {item?.image && <div className={styles.slideImageWrapper}>
                    <Image className={styles.slideImage} src={item.image} alt={t(item.key, item.default)} />
                  </div>}
                <div className={styles.slideDescription}>{t(item.key, item.default)}</div>
              </div>;
        })}
        </Slider>
        <div className={styles.pagination} />
      </div>
    </div>;
};