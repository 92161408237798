import instruction01 from '@public/assets/app/instruction01.webp';
import instruction02 from '@public/assets/app/instruction02.webp';
import instruction03 from '@public/assets/app/instruction03.webp';
import instruction04 from '@public/assets/app/instruction04.webp';
import instruction05 from '@public/assets/app/instruction05.webp';
import instruction06 from '@public/assets/app/instruction06.webp';
import instruction07 from '@public/assets/app/instruction07.webp';
import instruction08 from '@public/assets/app/instruction08.webp';
import instruction09 from '@public/assets/app/instruction09.webp';
import instruction10 from '@public/assets/app/instruction10.webp';
import instruction11 from '@public/assets/app/instruction11.webp';
import instruction12 from '@public/assets/app/instruction12.webp';
import instruction13 from '@public/assets/app/instruction13.webp';

import type { SliderProps } from '@/components/Slider/Slider';

import type { Data } from '../../AppPage.types';

import styles from './Instructions.module.scss';

export const instructions: Array<Data> = [
  {
    id: 1,
    key: 'launch-app-store',
    default: 'Launch the App Store app',
    image: instruction01,
  },
  {
    id: 2,
    key: 'click-profile-icon',
    default: 'Tap on the profile icon in the top right corner',
    image: instruction02,
  },
  {
    id: 3,
    key: 'click-account-name',
    default: 'In the opened menu, tap on your name in your account',
    image: instruction03,
  },
  {
    id: 4,
    key: 'enter-account-password',
    default: 'You will need the account password — enter it',
    image: instruction04,
  },
  {
    id: 5,
    key: 'select-country-region',
    default: "In the account settings menu, select the 'Country/Region' option",
    image: instruction05,
  },
  {
    id: 6,
    key: 'change-country-region',
    default: "Tap 'Change Country or Region' and agree to the terms",
    image: instruction06,
  },
  {
    id: 7,
    key: 'select-uzbekistan',
    default: 'Find and select Uzbekistan from the list of countries',
    image: instruction07,
  },
  {
    id: 8,
    key: 'enter-random-data',
    default:
      "Choose None as the payment method. In the 'Street', 'City', and 'Phone' fields, enter random data (even a random set of characters will do). Then, tap the 'Next' button",
    image: instruction08,
  },
  {
    id: 9,
    key: 'click-done',
    default: "Tap 'Done' and close the profile menu",
    image: instruction09,
  },
  {
    id: 10,
    key: 'search-and-install-banzai',
    default: 'Enter Banzai in the search field and install the app',
    image: instruction10,
  },
  {
    id: 11,
    key: 'error-create-new-account',
    default:
      'If there is an error, create a new account by going to appleid.apple.com. During registration of the new account, select Uzbekistan as the country',
    image: instruction11,
  },
  {
    id: 12,
    key: 'login-with-new-account',
    default: 'Sign in with the new account',
    image: instruction12,
  },
  {
    id: 13,
    key: 'install-banzai-again',
    default: 'Launch the App Store, enter Banzai in the search field and install the app',
    image: instruction13,
  },
];

export const defaultSliderProps: SliderProps = {
  navigation: true,
  pagination: {
    el: `.${styles.pagination}`,
    enabled: true,
    bulletClass: styles.paginationItem,
    bulletActiveClass: styles.paginationItemActive,
    clickable: true,
  },
  classes: {
    buttonLeftIcon: styles.buttonLeftIcon,
    buttonRightIcon: styles.buttonRightIcon,
  },
  centeredSlides: true,
  keyboard: true,
};
