import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import BackElementsXlImage from '@public/assets/app/backElementsXl.webp';
import FrontElementsMdImage from '@public/assets/app/frontElementsMd.webp';
import FrontElementsSmImage from '@public/assets/app/frontElementsSm.webp';
import FrontElementsXlImage from '@public/assets/app/frontElementsXl.webp';
import FrontElementsXsImage from '@public/assets/app/frontElementsXs.webp';
import PhonesImage from '@public/assets/app/phones.webp';
import PhonesMobileImage from '@public/assets/app/phonesMobile.webp';
import { Image } from '@/components/Image/Image';
import { useMediaWidthDown } from '@/hooks';
import { DownloadBenefits } from './components/DownloadBenefits/DownloadBenefits';
import { DownloadButton } from './components/DownloadButton/DownloadButton';
import { Tabs } from './components/Tabs/Tabs';
import { downloadAnalytics } from './AppPage.analytics';
import styles from './AppPage.module.scss';
export const AppPage: FC = () => {
  const {
    t
  } = useTranslation('promo', {
    keyPrefix: 'app'
  });
  const isScreenMd = useMediaWidthDown('screenMd');
  const isScreenSm = useMediaWidthDown('screenSm');
  const isScreenXs = useMediaWidthDown('screenXs');
  const frontElementsMdImage = useMemo(() => {
    if (isScreenXs) {
      return FrontElementsXsImage;
    }
    if (isScreenSm) {
      return FrontElementsSmImage;
    }
    if (isScreenMd) {
      return FrontElementsMdImage;
    }
    return FrontElementsXlImage;
  }, [isScreenMd, isScreenSm, isScreenXs]);
  return <div className={styles.root} data-sentry-component="AppPage" data-sentry-source-file="AppPage.tsx">
      <div className={styles.top}>
        <div className={styles.textContent}>
          <h1 className={styles.title}>{t('title', 'Go mobile')}</h1>
          <p className={styles.subtitle}>{t('subtitle', 'Mobile applications of')}</p>
          <div className={styles.downloads}>
            <DownloadButton platform="ios" analytics={downloadAnalytics.ios} data-sentry-element="DownloadButton" data-sentry-source-file="AppPage.tsx" />
            <DownloadButton platform="android" analytics={downloadAnalytics.android} data-sentry-element="DownloadButton" data-sentry-source-file="AppPage.tsx" />
          </div>
        </div>
        <Image src={BackElementsXlImage} className={styles.backElements} priority alt="" data-sentry-element="Image" data-sentry-source-file="AppPage.tsx" />
        <Image src={frontElementsMdImage} className={styles.frontElements} priority alt="" data-sentry-element="Image" data-sentry-source-file="AppPage.tsx" />
        <div className={styles.phonesImage}>
          <Image src={isScreenXs ? PhonesMobileImage : PhonesImage} priority alt={t('title', 'Go mobile')} fill data-sentry-element="Image" data-sentry-source-file="AppPage.tsx" />
        </div>
        <DownloadBenefits data-sentry-element="DownloadBenefits" data-sentry-source-file="AppPage.tsx" />
      </div>

      <Tabs data-sentry-element="Tabs" data-sentry-source-file="AppPage.tsx" />
      <h2 className={styles.footerText}>{t('footer', 'THE TIME HAS COME\nFOR YOU TO WIN!')}</h2>
    </div>;
};