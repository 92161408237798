import { useRouter } from 'next/router';
import { Image } from '@/components/Image/Image';
import { Tab } from '@/components/Tab/Tab';
import { Tabs as UiTabs } from '@/components/Tabs/Tabs';
import { DownloadSteps } from '../DownloadSteps/DownloadSteps';
import { tabs } from './Tabs.data';
import { getIsActiveTab } from './Tabs.utils';
import styles from './Tabs.module.scss';
export const Tabs = () => {
  const {
    query
  } = useRouter();
  return <div className={styles.root} data-sentry-component="Tabs" data-sentry-source-file="Tabs.tsx">
      <div className={styles.container}>
        <UiTabs className={styles.tabs} fullWidth grow data-sentry-element="UiTabs" data-sentry-source-file="Tabs.tsx">
          {tabs.map(({
          platform,
          tab
        }, index) => {
          const isActive = getIsActiveTab(query, platform, index);
          const {
            label,
            image,
            ...restTab
          } = tab;
          return <Tab key={platform} className={cn(styles.tab, {
            [styles.tabActive]: isActive
          })} active={isActive} label={<span className={styles.tabLabel}>
                    <Image src={image} width={90} height={90} alt={label} className={styles.tabLabelIcon} />
                    {label}
                  </span>} {...restTab} />;
        })}
        </UiTabs>

        <div>
          {tabs.map(({
          platform,
          steps,
          qrLink
        }, index) => {
          const isActive = getIsActiveTab(query, platform, index);
          return <div key={platform} className={styles.tabContent} hidden={!isActive}>
                <DownloadSteps steps={steps} platform={platform} qrLink={qrLink} />
              </div>;
        })}
        </div>
      </div>
    </div>;
};