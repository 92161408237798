import tabAndroid from '@public/assets/app/tabAndroid.webp';
import tabIOS from '@public/assets/app/tabIOS.webp';

import type { Tabs } from './Tabs.types';

export const tabs: Tabs = [
  {
    platform: 'android',
    tab: {
      as: 'link',
      label: 'Android',
      href: {
        query: {
          platform: 'android',
        },
      },
      image: tabAndroid,
    },
    steps: {
      title: {
        key: 'guides_title',
        default: 'Step by step guide to download Android App',
      },
      items: [
        {
          id: 1,
          key: 'guides_item_1',
          default: 'Click on the "Download" button below from your Android device.',
        },
        {
          id: 2,
          key: 'guides_item_2',
          default:
            'Press "Install" when the download is completed. It may happen that your device will ask permission to download the App from an unknown source.',
        },
        {
          id: 3,
          key: 'guides_item_3',
          default: 'Go to application, log in and get ready to win!',
        },
      ],
    },
    qrLink: 'https://banzai.bet/api/user/v1/apk/download',
  },
  {
    platform: 'ios',
    tab: {
      as: 'link',
      label: 'iOS',
      href: {
        query: {
          platform: 'ios',
        },
      },
      image: tabIOS,
    },
    steps: {
      title: {
        key: 'guides_title_ios',
        default: 'Step by step guide to download IOS App',
      },
      items: [
        {
          id: 1,
          key: 'guides_item_ios_1',
          default: 'The Banzai app is available on the App Store.',
        },
        {
          id: 2,
          key: 'guides_item_ios_2',
          default:
            'To download the app to an IOS device, your account must be registered in any of the following countries:  Azerbaijan, Belarus, India, Kazakhstan, Kyrgyzstan, Nepal, Pakistan, Peru, Tajikistan, Turkmenistan, Uzbekistan.',
        },
        {
          id: 3,
          key: 'guides_item_ios_3',
          default:
            'To find more information on the availability of the IOS app in you country, contact Support Service.',
        },
        {
          id: 4,
          key: 'guides_item_ios_4',
          default:
            'To download the Banzai app on an IOS device if it is not available on App Store in your country, follow these <0>instructions</0>.',
        },
        {
          id: 5,
          key: 'guides_item_ios_5',
          default: 'The app is ready to go!',
        },
      ],
    },
    qrLink: 'https://apps.apple.com/app/banzai-bet/id6467190917',
  },
];
